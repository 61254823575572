import React from 'react';

import Content from '../../components/Content';

const Unsupported: React.FC = () => (
    <Content data-testid={IS_TESTING && 'unsupported'}>
        <h1>Browser not supported</h1>
        <p>This browser doesn&apos;t support required features - try opening this app in a different browser.</p>
    </Content>
);

export default Unsupported;
