import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { WifiNetwork } from '../../types/network';
import { useAuth } from '../../services/auth';
import { useBluetooth } from '../../services/bluetooth';
import BaseStyle from '../../styles/BaseStyle';
import Completed from '../Completed';
import ConnectToNetwork from '../ConnectToNetwork';
import ConnectedToNetwork from '../ConnectedToNetwork';
import Enrol from '../Enrol';
import Footer from '../Footer';
import LogIn from '../LogIn';
import Pair from '../Pair';
import Paired from '../Paired';
import Replicate from '../Replicate';
import SelectContent from '../SelectContent';
import SelectNetwork from '../SelectNetwork';
import ShowContent from '../ShowContent';
import Unsupported from '../Unsupported';
import Welcome from '../Welcome';

const App: React.FC = () => {
    const history = useHistory();
    const bluetooth = useBluetooth();
    const { user, logOut } = useAuth();
    const [selectedNetwork, setSelectedNetwork] = useState<WifiNetwork>();
    const [contentWarning, setContentWarning] = useState<string>();

    const onLogOut = () => {
        logOut();
        history.push('/');
    };

    useEffect(() => {
        if (window.Cypress) {
            window.router = { history };
        }
    }, []);

    useEffect(() => {
        if (selectedNetwork) {
            history.push('/connect-to-network');
        }
    }, [selectedNetwork]);

    return (
        <Wrap>
            <BaseStyle />
            {bluetooth.isAvailable && (
                <Switch>
                    <Route path="/" exact>
                        <Welcome
                            onNext={() =>
                                history.push(user ? '/pair' : '/login')
                            }
                        />
                    </Route>
                    {!user && (
                        <Route path="/login" exact>
                            <LogIn onLogIn={() => history.push('/pair')} />
                        </Route>
                    )}

                    {!!user && (
                        <Route path="/pair" exact>
                            <Pair onPaired={() => history.push('/paired')} />
                        </Route>
                    )}

                    {!!bluetooth.device && (
                        <Route path="/paired" exact>
                            <Paired
                                onNext={() => history.push('/select-network')}
                            />
                        </Route>
                    )}

                    {!!bluetooth.device && (
                        <Route path="/select-network" exact>
                            <SelectNetwork onSelect={setSelectedNetwork} />
                        </Route>
                    )}

                    {!!bluetooth.device && !!selectedNetwork && (
                        <Route path="/connect-to-network" exact>
                            <ConnectToNetwork
                                network={selectedNetwork}
                                onConnected={() =>
                                    history.push('/connected-to-network')
                                }
                                onCancel={() => history.push('/select-network')}
                            />
                        </Route>
                    )}

                    {!!bluetooth.device && !!bluetooth.network && (
                        <Route path="/connected-to-network" exact>
                            <ConnectedToNetwork
                                onNext={() => history.push('/enrol')}
                            />
                        </Route>
                    )}

                    {!!bluetooth.device && !!bluetooth.network && (
                        <Route path="/enrol" exact>
                            <Enrol
                                onEnrolled={() =>
                                    history.push('/select-content')
                                }
                            />
                        </Route>
                    )}

                    {!!bluetooth.device && !!bluetooth.network && (
                        <Route path="/show-content" exact>
                            <ShowContent
                                onNext={() =>
                                    history.push('/select-content')
                                }
                                onSkip={() => history.push('/completed')}
                            />
                        </Route>
                    )}

                    {!!bluetooth.device && !!bluetooth.network && (
                        <Route path="/select-content" exact>
                            <SelectContent
                                onNext={(warning: string) => {
                                    setContentWarning(warning);
                                    history.push('/completed');
                                }}
                                onSkip={() => history.push('/completed')}
                            />
                        </Route>
                    )}

                    {!!bluetooth.device && !!bluetooth.network && (
                        <Route path="/completed" exact>
                            <Completed
                                warning={contentWarning}
                                onReplicate={() =>
                                    history.push('/replicate')
                                }
                                onSetupNewDevice={() =>
                                    history.push('/pair')
                                }
                            />
                        </Route>
                    )}

                    {!!bluetooth.device && !!bluetooth.network && (
                        <Route path="/replicate" exact>
                            <Replicate
                                onDone={() => history.push('/completed')}
                            />
                        </Route>
                    )}

                    <Redirect to="/" />
                </Switch>
            )}
            {!bluetooth.isAvailable && <Unsupported />}
            <Footer onLogOut={user && onLogOut} />
        </Wrap>
    );
};

export default App;

const Wrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    padding-bottom: 2rem;
    text-align: center;
    width: 100vw;
`;
