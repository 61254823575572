import React from 'react';
import styled from 'styled-components';

import { useBluetooth } from '../../services/bluetooth';
import Button from '../../components/Button';
import Content from '../../components/Content';
import helmet from '../SelectNetwork/helmet.svg';

interface Props {
    onNext: () => void;
}

const ConnectedToNetwork: React.FC<Props> = ({ onNext }) => {
    const { network } = useBluetooth();
    return (
        <Wrap data-testid={IS_TESTING && 'connected-to-network'}>
            <img src={helmet} />
            <h1>
                Device now connected to <small>&lsquo;{network.ssid}&rsquo;</small>
            </h1>
            <Button testId="next-button" onClick={onNext}>Continue</Button>
        </Wrap>
    );
};

export default ConnectedToNetwork;

const Wrap = styled(Content)`
    flex: 1 0 auto;

    h1 {
        text-align: center;

        small {
            text-transform: none;
            font-size: 0.75em;
            margin-top: 0.5rem;
        }
    }

    img {
        flex: 0 0 auto;
        margin: 0 0.5rem 1rem;
        height: auto;
        width: 8rem;
    }
`;
