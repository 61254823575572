import React from 'react';
import styled from 'styled-components';

import logo from './logo.svg';

interface Props {
    helpLink?: string;
    onLogOut?: () => void;
}

const Footer: React.FC<Props> = ({ helpLink, onLogOut }) => {
    const onClickLogOut = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        onLogOut();
    };
    return (
        <Wrap>
            {!!helpLink && <Help href={helpLink}>Help</Help>}
            <Logo src={logo} />
            {!!onLogOut && (
                <Logout onClick={onClickLogOut} href="#">
                    Log out
                </Logout>
            )}
        </Wrap>
    );
};

export default Footer;

const Help = styled.a`
    grid-column: 1;
    opacity: 0.6;
`;

const Logo = styled.img`
    grid-column: 2;
    max-height: 1.5rem;
    width: auto;
`;

const Logout = styled.a`
    grid-column: 3;
    opacity: 0.6;
`;

const Wrap = styled.div`
    align-content: center;
    display: grid;
    flex: 0 0 auto;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    margin-top: 1rem;
`;
