import { concat, from, interval } from 'rxjs';
import { concatMap, startWith, takeWhile } from 'rxjs/operators';

import { SingleCharBluetoothDeviceInterface as BluetoothDeviceInterface } from '../types/bluetooth';
import { WifiNetwork, WifiStatus } from '../types/network';
import * as bluetoothRequests from './bluetoothRequests';

export const WIFI_STATUS_POLL_INTERVAL_MS = 5000;
export const INITIAL_BATCH_SIZE = 20;
export const REGULAR_BATCH_SIZE = 200;

const END_WIFI_STATUSES = [
    WifiStatus.CONNECTED,
    WifiStatus.UNAUTHENTICATED,
    WifiStatus.DISCONNECTED,
];

export const joinWifiNetwork = (
    deviceInterface: BluetoothDeviceInterface,
    network: WifiNetwork,
    passphrase: string
) =>
    bluetoothRequests
        .joinWifiNetwork(
            deviceInterface,
            network.ssid
                ? { ssid: network.ssid, passphrase }
                : { bssid: network.bssid, passphrase }
        )
        .pipe(
            takeWhile(
                (status: WifiStatus) => !END_WIFI_STATUSES.includes(status),
                true
            ),
            startWith(WifiStatus.CONNECTING)
        );

export const searchForWifiNetworks = (
    deviceInterface: BluetoothDeviceInterface
) =>
    concat(
        from([INITIAL_BATCH_SIZE, REGULAR_BATCH_SIZE]).pipe(
            concatMap((numberOfNetworks: number) =>
                bluetoothRequests.getWifiNetworks(deviceInterface, {
                    numberOfNetworks,
                })
            ),
        ),
        interval(WIFI_STATUS_POLL_INTERVAL_MS).pipe(
            concatMap(() =>
                bluetoothRequests.getWifiNetworks(deviceInterface, {
                    numberOfNetworks: REGULAR_BATCH_SIZE,
                })
            )
        )
    );
