import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    DeviceContent,
    DeviceContentType,
    UrlContent,
} from '../../types/content';
import BaseContent from '../../components/Content';
import Field from '../../components/Field';
import Input from '../../components/Input';
import TabScreen from '../../components/Tabs/TabScreen';

// Thanks to 3limin4t0r for this one (https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url)
const URL_PATTERN = new RegExp(
    '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
    'i'
);

const isUrl = (url: string) => URL_PATTERN.test(url);

interface Props {
    onSelect: (selected?: UrlContent) => void;
    selected?: DeviceContent;
}

const UrlTabScreen: React.FC<Props> = ({ onSelect, selected }) => {
    const [url, setUrl] = useState<string>('');
    useEffect(() => {
        if (!url) {
            return;
        }

        if (selected && selected?.type !== DeviceContentType.URL) {
            setUrl('');
        }
    }, [selected]);

    return (
        <TabScreen label="URL" tabTestId="url-tab">
            <Content>
                <p>Enter a URL below to display</p>
                <Field placeholder="Enter URL">
                    <Input
                        type="url"
                        value={url}
                        data-testid={IS_TESTING && 'url-input'}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setUrl(event.target.value);
                            if (isUrl(event.target.value)) {
                                const content: UrlContent = {
                                    type: DeviceContentType.URL,
                                    url: event.target.value,
                                };
                                onSelect(content);
                            } else if (selected) {
                                onSelect(undefined);
                            }
                        }}
                    />
                </Field>
            </Content>
        </TabScreen>
    );
};

const Content = styled(BaseContent)`
    @media (min-width: 640px) {
        justify-content: flex-start;
    }
`;

export default UrlTabScreen;
