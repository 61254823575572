import { Subject, fromEvent } from 'rxjs';
import {
    concatMap,
    filter,
    finalize,
    map,
    publish,
    scan,
    tap,
} from 'rxjs/operators';

import {
    BluetoothCharChangeEvent,
    BluetoothRequest,
    BluetoothResponse,
    MultiCharBluetoothDeviceInterface,
    SingleCharBluetoothDeviceInterface,
} from '../types/bluetooth';
import { DeviceCharacteristics } from './characteristic';
import * as Bluetooth from './bluetooth';
import {
    END_MARKER,
    MELD_DEVICE_UUID,
    MELD_READ_CHARACTERISTIC_ID,
    MELD_WRITE_CHARACTERISTIC_ID,
    START_MARKER,
    call,
    from,
    request,
    send,
} from './bluetooth';

export const getMultiCharInterface = async (device: {
    gatt?: { connect: () => void };
}): Promise<MultiCharBluetoothDeviceInterface> => {
    const getGatt = Bluetooth.getGatt.bind(null, device);
    const getService = Bluetooth.getService.bind(null, getGatt);

    const queue$ = new Subject<() => Promise<any>>();
    queue$.pipe(concatMap(callback => callback())).subscribe();

    const callCharacteristic = Bluetooth.callCharacteristic.bind(
        null,
        getService
    );

    const getCharacteristicValue = Bluetooth.getCharacteristicValue.bind(
        null,
        getService
    );

    const setCharacteristicValue = Bluetooth.setCharacteristicValue.bind(
        null,
        getService
    );

    return {
        call: async (attribute: string) => {
            const characteristic = DeviceCharacteristics.call[attribute];
            if (!characteristic) {
                throw new Error(
                    `Unable to call '${attribute}' - not a known callable characteristic`
                );
            }
            return new Promise(resolve => {
                queue$.next(() =>
                    callCharacteristic(characteristic).then(resolve)
                );
            });
        },

        get: async (attribute: string) => {
            if (attribute in DeviceCharacteristics.getString) {
                const characteristic =
                    DeviceCharacteristics.getString[attribute];

                return new Promise(resolve => {
                    queue$.next(() =>
                        getCharacteristicValue(characteristic, 'string').then(
                            resolve
                        )
                    );
                });
            } else if (attribute in DeviceCharacteristics.getNumber) {
                const characteristic =
                    DeviceCharacteristics.getNumber[attribute];

                return new Promise(resolve => {
                    queue$.next(() =>
                        getCharacteristicValue(characteristic, 'number').then(
                            resolve
                        )
                    );
                });
            }

            throw new Error(
                `Unable to get '${attribute}' - not a known gettable characteristic`
            );
        },

        set: async (attribute: string, value: string | number) => {
            const characteristic = DeviceCharacteristics.set[attribute];
            if (!characteristic) {
                throw new Error(
                    `Unable to set '${attribute}' - not a known settable characteristic`
                );
            }

            return new Promise(resolve => {
                queue$.next(() =>
                    setCharacteristicValue(characteristic, value).then(resolve)
                );
            });
        },
    };
};

export const getSingleCharInterface = async (device: {
    gatt?: {
        connected: boolean;
        connect: () => Promise<BluetoothRemoteGATTServer>;
        getPrimaryService: (
            service: BluetoothServiceUUID
        ) => Promise<BluetoothRemoteGATTService>;
    };
}): Promise<SingleCharBluetoothDeviceInterface> => {
    if (!device.gatt) {
        throw new Error('Bluetooth GATT service not available');
    }

    if (!device.gatt.connected) {
        await device.gatt.connect();
    }

    const service = await device.gatt.getPrimaryService(MELD_DEVICE_UUID);

    const inputCharacteristic = await service.getCharacteristic(
        MELD_WRITE_CHARACTERISTIC_ID
    );
    const outputCharacteristic = await service.getCharacteristic(
        MELD_READ_CHARACTERISTIC_ID
    );

    const decoder = new TextDecoder('utf-8');

    // @types/web-bluetooth doesn't support proper event type for characteristicvaluechanged
    const valueChange$ = fromEvent<any>(
        outputCharacteristic,
        'characteristicvaluechanged'
    );

    // @NOTE(adam): looks weird, but it's a workaround for RxJS type defs (https://github.com/ReactiveX/rxjs/issues/3595)
    const responses$ = publish<BluetoothResponse>()(
        valueChange$.pipe(
            map((event: BluetoothCharChangeEvent) =>
                decoder.decode(event.target.value)
            ),
            tap((info: string) => console.log(`RECEIVED ${info}`)),
            scan((message: string, next: string) => {
                message += next;
                const hasSecondStartMarker = message.includes(START_MARKER, 1);
                return hasSecondStartMarker
                    ? START_MARKER + message.split(START_MARKER).pop()
                    : message;
            }, ''),
            filter((message: string) => message.endsWith(END_MARKER)),
            tap((value: string) => console.warn(`MESSAGE ${value}`)),
            map((message: string) =>
                JSON.parse(
                    message.substring(
                        START_MARKER.length,
                        message.length - END_MARKER.length
                    )
                )
            ),
            tap((message: any) =>
                console.warn(`DATA ${JSON.stringify(message)}`)
            ),
            finalize(() => {
                outputCharacteristic.stopNotifications();
            })
        )
    );

    responses$.connect();
    outputCharacteristic.startNotifications();
    let lastRequestId = 0;

    const queue$ = new Subject<() => Promise<void>>();
    queue$.pipe(concatMap(callback => callback())).subscribe();

    const sendMessage = <T>(request: BluetoothRequest<T>) =>
        send(request, queue$, inputCharacteristic);

    return {
        call: <T>(command: string, data: T) =>
            call<T>(sendMessage, lastRequestId++, command, data),

        request: <T, R>(command: string, data: T, dataType?: string) =>
            request<T, R>(
                sendMessage,
                lastRequestId++,
                command,
                data,
                responses$,
                dataType
            ),

        from: <T, R>(command: string, data: T, dataType?: string) =>
            from<T, R>(
                sendMessage,
                lastRequestId++,
                command,
                data,
                responses$,
                dataType
            ),
    };
};

export const getBluetoothDeviceInterface = getSingleCharInterface;
