import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

const TabContext = React.createContext<TabsAPI>({ updateTab: () => 0 });
const TabProvider = TabContext.Provider;
const TAB_WIDTH_PX = 96;
const SCROLL_TIME_RELEVANCE = 50;

interface ScrollPoint {
    position: number;
    time: number;
}

export interface TabsAPI {
    activeTabId?: number;
    updateTab: (index: number | undefined, info: TabInfo) => number;
}

export interface TabInfo {
    label: string;
    testId?: string;
}

interface Props {
    onChange?: (index: number) => void;
}

export const useTabs = () => useContext<TabsAPI>(TabContext);

const Tabs: React.FC<Props> = ({ children, onChange }) => {
    const [tabs, setTabs] = useState<TabInfo[]>([]);
    const latestTabs = useRef<TabInfo[]>([]);

    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const latestActiveTabIndex = useRef<number>(0);

    const menu = useRef<HTMLUListElement>();
    const screens = useRef<HTMLDivElement>();

    const api: TabsAPI = {
        activeTabId: activeTabIndex,
        updateTab: (index: number | undefined, info: TabInfo) => {
            if (index === undefined) {
                index = latestTabs.current.length;
            }

            latestTabs.current[index] = { ...info };
            setTabs(latestTabs.current);
            return index;
        },
    };

    const scrollTo = (index: number) => {
        screens.current.scrollTo({
            left: index * screens.current.offsetWidth,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        let request: number;
        let lastScrollPoints: ScrollPoint[] = [];
        let speed = 0;
        let startIndex = latestActiveTabIndex.current;

        const snap = () => {
            const pixelsPerSecond = speed * 1000;

            // Estimate position after 0.5s
            const endPosition =
                screens.current.scrollLeft + pixelsPerSecond * 0.5;

            const newTabIndex = Math.min(
                startIndex + 1,
                Math.max(
                    startIndex - 1,
                    Math.round(endPosition / screens.current.offsetWidth)
                )
            );
            scrollTo(newTabIndex);
        };

        const trackScroll = () => {
            const now = performance.now();

            if (lastScrollPoints.length) {
                const lastScrollPoint =
                    lastScrollPoints[lastScrollPoints.length - 1];
                const distance =
                    screens.current.scrollLeft - lastScrollPoint.position;
                const duration = now - lastScrollPoint.time;
                speed = distance / duration;
            }

            lastScrollPoints = lastScrollPoints.filter(
                point => now - point.time < SCROLL_TIME_RELEVANCE
            );

            lastScrollPoints.push({
                position: screens.current.scrollLeft,
                time: now,
            });
        };

        const trackStart = () => {
            startIndex = latestActiveTabIndex.current;
        };

        const updateScroll = () => {
            const screensScrolled =
                screens.current.scrollLeft / screens.current.offsetWidth;

            const screenIndex = Math.round(screensScrolled);
            if (latestActiveTabIndex.current !== screenIndex) {
                latestActiveTabIndex.current = screenIndex;
                setActiveTabIndex(screenIndex);
                if (onChange) {
                    onChange(screenIndex);
                }
            }

            // Show opacity change
            [...screens.current.children].forEach(
                (screen: HTMLLIElement, s) => {
                    screen.style.opacity = Math.max(
                        0,
                        1 - Math.abs(s - screensScrolled) / 0.5
                    ).toString();
                }
            );
        };

        const scheduleScrollUpdate = () => {
            if (request) {
                window.cancelAnimationFrame(request);
            }
            request = window.requestAnimationFrame(updateScroll);
        };

        screens.current.addEventListener('scroll', trackScroll);
        screens.current.addEventListener('scroll', scheduleScrollUpdate);
        screens.current.addEventListener('touchend', snap);
        screens.current.addEventListener('touchstart', trackStart);
        return () => {
            screens.current.removeEventListener('scroll', trackScroll);
            screens.current.removeEventListener('scroll', scheduleScrollUpdate);
            screens.current.removeEventListener('touchend', snap);
            screens.current.removeEventListener('touchstart', trackStart);
        };
    }, []);

    return (
        <Wrap>
            <Menu
                ref={menu}
                style={
                    {
                        '--active-tab-index': activeTabIndex,
                        '--tab-count': tabs.length,
                    } as any
                }
            >
                {tabs.map(({ label, testId }, t) => (
                    <Tab
                        className={classnames({ active: activeTabIndex === t })}
                        data-testid={IS_TESTING && testId}
                        onClick={() => scrollTo(t)}
                        key={t}
                    >
                        {label}
                    </Tab>
                ))}
            </Menu>
            <Screens
                style={{ '--tab-count': tabs.length } as any}
                ref={screens}
            >
                <TabProvider value={api}>{children}</TabProvider>
            </Screens>
        </Wrap>
    );
};

export default Tabs;

const Menu = styled.ul`
    --active-tab-index: 0;
    --tab-width: ${TAB_WIDTH_PX}px;
    background: var(--shade-1);
    display: flex;
    flex: 0 0 auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &:after {
        background: currentColor;
        bottom: 0;
        content: '';
        height: 0.125rem;
        left: 0;
        position: absolute;
        transform: translateX(calc(var(--active-tab-index) * var(--tab-width)));
        transition: 150ms transform;
        width: var(--tab-width);
    }

    @media (min-width: 640px) {
        background: none;
        justify-content: center;

        &:after {
            left: 50%;
            margin-left: calc(var(--tab-width) * var(--tab-count) * -0.5);
            transform: translateX(calc(var(--active-tab-index) * var(--tab-width)));
        }
    }
`;

const Screens = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1;
    overflow-x: auto;
    perspective: 1px;
    perspective-origin: 50% 50%;

    &:before {
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: 0;
        width: calc(var(--tab-count) * 100%);
        z-index: 0;
    }

    @media (min-width: 640px) {
        overflow: hidden;
    }
`;

const Tab = styled.li`
    cursor: pointer;
    flex: 0 0 auto;
    list-style: none;
    opacity: 0.5;
    padding: 0.75rem 0;
    transition: 75ms opacity;
    user-select: none;
    width: var(--tab-width);

    :hover {
        opacity: 0.6;
    }

    &.active {
        opacity: 1;
    }
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    width: 100%;
`;
