import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    AppContent,
    DeviceContent,
    DeviceContentType,
} from '../../types/content';
import { Application } from '../../types/application';
import { TabScreenWrap } from '../../components/Tabs/TabScreen';
import { useBluetooth } from '../../services/bluetooth';
import { useMeld } from '../../services/meld';
import { useReplay } from '../../services/replay';
import AppTabScreen from './AppTabScreen';
import Button, { ButtonWrap } from '../../components/Button';
import Content from '../../components/Content';
import Tabs from '../../components/Tabs';
import UrlTabScreen from './UrlTabScreen';

interface Props {
    onNext: (error?: string) => void;
    onSkip: () => void;
}

const CONTENT_TYPES = [DeviceContentType.URL, DeviceContentType.APP];

const SelectContent: React.FC<Props> = ({ onNext, onSkip }) => {
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);
    const { getApplications } = useMeld();
    const { rememberContent } = useReplay();
    const [availableAppContent, setAvailableAppContent] = useState<
        AppContent[]
    >([]);

    useEffect(() => {
        getApplications().then((applications: Application[]) => {
            setAvailableAppContent(
                applications.map(app => ({
                    type: DeviceContentType.APP,
                    id: app.id,
                    icon: app.icon,
                    name: app.name,
                }))
            );
        });
    }, []);

    const { getDeviceIdentifiers } = useBluetooth();
    const { loadContent } = useMeld();
    const [selected, setSelected] = useState<DeviceContent>();
    const [type, setType] = useState<DeviceContentType>(
        DeviceContentType.URL
    );
    const onSelect = async () => {
        const { deviceId } = await getDeviceIdentifiers();

        try {
            setLoading(true);
            await loadContent(deviceId, selected);
        } catch (error) {
            // @NOTE(adam): we can skip the final steps and still remember config if no displays
            if (error.name === 'DisplayMissingError') {
                rememberContent(selected);
                onNext(error.message);
            } else {
                setError(error.message);
                setLoading(false);
            }
            return;
        }
        rememberContent(selected);
        onNext();
    };
    return (
        <Wrap data-testid={IS_TESTING && 'select-content'}>
            <Tabs onChange={index => setType(CONTENT_TYPES[index])}>
                <UrlTabScreen selected={selected} onSelect={setSelected} />
                <AppTabScreen
                    onSelect={setSelected}
                    options={availableAppContent}
                    selected={selected}
                />
            </Tabs>

            {error && <ErrorStatus>{error}</ErrorStatus>}

            <Buttons>
                <Button
                    background="var(--secondary)"
                    color="var(--foreground)"
                    onClick={!loading ? onSkip : undefined}
                    testId="skip-button"
                >
                    Skip
                </Button>
                <Button
                    onClick={(!loading && selected?.type === type) ? onSelect : undefined}
                    testId="select-button"
                >
                    {loading ? 'Loading...' : 'Select'}
                </Button>
            </Buttons>
        </Wrap>
    );
};

export default SelectContent;

const Buttons = styled.div`
    display: flex;
    margin: 1rem auto;
    max-width: 18rem;
    width: 100%;

    ${ButtonWrap} {
        flex: 2 1 0;
        min-width: 0;

        + ${ButtonWrap} {
            flex: 3 1 0;
            margin-left: 1rem;
        }
    }
`;

const ErrorStatus = styled.p`
    color: var(--warning);
`;

const Wrap = styled(Content)`
    padding: 0;
    margin: 0;
    max-width: none;
    width: 100%;

    @media (min-width: 640px) {
        max-width: none;
    }

    ${TabScreenWrap} {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
`;
