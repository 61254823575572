import { Subscription } from 'rxjs';
import { last } from 'rxjs/operators';
import { useEffect, useRef, useState } from 'react';

import {
    BluetoothDeviceAPI,
    SingleCharBluetoothDeviceInterface as BluetoothDeviceInterface,
} from '../types/bluetooth';
import { WifiNetwork, WifiStatus } from '../types/network';
import { getBluetoothDeviceInterface } from '../helpers/bluetoothDevice';
import {
    joinWifiNetwork,
    searchForWifiNetworks,
} from '../helpers/bluetoothWifi';
import * as bluetoothRequests from '../helpers/bluetoothRequests';

export default (
    device?: BluetoothDevice,
    autoloadNetworks: boolean = false
) => {
    const deviceInterface = useRef<BluetoothDeviceInterface | undefined>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        deviceInterface.current = undefined;
        setLoaded(false);

        if (device) {
            getBluetoothDeviceInterface(device).then(newInterface => {
                deviceInterface.current = newInterface;
                setLoaded(true);
            });
        }
    }, [device]);

    const wifiNetworksSubscription = useRef<Subscription>();
    const wifiStatusSubscription = useRef<Subscription>();

    // @TODO(adam): investigate how to retrieve currently connected network
    const [network, setNetwork] = useState<WifiNetwork | undefined>(undefined);
    const [networks, setNetworks] = useState<WifiNetwork[]>([]);
    const [joinStatus, setJoinStatus] = useState<WifiStatus>(
        WifiStatus.DISCONNECTED
    );

    const join = async (network: WifiNetwork, passphrase: string) =>
        new Promise<WifiNetwork>((resolve, reject) => {
            wifiStatusSubscription.current?.unsubscribe();
            const joinStatus$ = joinWifiNetwork(
                deviceInterface.current,
                network,
                passphrase
            );

            joinStatus$.pipe(last()).subscribe((status: WifiStatus) => {
                if (status === WifiStatus.CONNECTED) {
                    setNetwork(network);
                    resolve(network);
                }
                reject();
            });

            const subscription = joinStatus$.subscribe((status: WifiStatus) =>
                setJoinStatus(status)
            );

            wifiStatusSubscription.current = subscription;
        });

    const search = async () => {
        wifiNetworksSubscription.current?.unsubscribe();
        const networks$ = await searchForWifiNetworks(deviceInterface.current);
        const subscription = networks$.subscribe(setNetworks);
        wifiNetworksSubscription.current = subscription;
        return subscription;
    };

    useEffect(() => {
        if (device && autoloadNetworks) {
            search();
        }

        return () => {
            wifiNetworksSubscription.current?.unsubscribe();
            wifiStatusSubscription.current?.unsubscribe();
        };
    }, [device]);

    const api: BluetoothDeviceAPI = {
        getDeviceIdentifiers: () =>
            bluetoothRequests.getDeviceIdentifiers(deviceInterface.current),
        joinStatus,
        joinWifiNetwork: join,
        loaded,
        network,
        networks,
        searchForWifiNetworks: search,
    };

    return api;
};
