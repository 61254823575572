export const DeviceCharacteristics: {
    getString: Record<string, string>;
    getNumber: Record<string, string>;
    set: Record<string, string>;
    call: Record<string, string>;
} = {
    getNumber: {
        id: '00002aaf-0000-1000-8000-00805f9b34fb',
        wifiCount: '00002abc-0000-1000-8000-00805f9b34fb',
    },

    getString: {
        name: '00002aba-0000-1000-8000-00805f9b34fb',
        serial: '00002abb-0000-1000-8000-00805f9b34fb',
        wifiStatus: '00002ac3-0000-1000-8000-00805f9b34fb',
        wifiValue: '00002abe-0000-1000-8000-00805f9b34fb',
    },

    set: {
        wifiAttribute: '00002abf-0000-1000-8000-00805f9b34fb',
        wifiBSSID: '00002ac0-0000-1000-8000-00805f9b34fb',
        wifiSSID: '00002ac4-0000-1000-8000-00805f9b34fb',
        wifiIndex: '00002abd-0000-1000-8000-00805f9b34fb',
        wifiPassphrase: '00002ac1-0000-1000-8000-00805f9b34fb',
    },

    call: {
        connectWithSSID: '00002ac5-0000-1000-8000-00805f9b34fb',
        connectWithBSSID: '00002ac2-0000-1000-8000-00805f9b34fb',
    },
};
