export interface BaseWifiNetwork {
    bssid?: string;
    ssid?: string;
    signalStrength?: number;
}

export enum WifiStatus {
    DISCONNECTED = 'disconnected',
    CONNECTING = 'connecting',
    CHECKING_CONNECTIVITY = '',
    CONNECTED = 'connected',
    UNAUTHENTICATED = 'unavailable',
    NO_CONNECTIVITY = 'unavailable',
}

export interface DirectWifiNetwork extends BaseWifiNetwork {
    bssid: string;
}

export interface FriendlyWifiNetwork extends BaseWifiNetwork {
    ssid: string;
}

export type WifiNetwork = DirectWifiNetwork | FriendlyWifiNetwork;
