import {
    AppContent,
    DeviceContent,
    DeviceContentType,
    UrlContent,
} from '../types/content';
import {
    Application,
    ApplicationType,
    DraftApplication,
} from '../types/application';
import { Display } from '../types/display';
import { DisplayMissingError } from '../errors/DisplayMissingError';
import {
    DraftVersion,
    Version,
    VersionChannel,
    VersionStatus,
} from '../types/version';
import { Schedule } from '../types/schedule';
import { User } from '../types/user';

const ONBOARDING_APPLICATION_NAME = 'Onboarding Demo App';
const ONBOARDING_SCHEDULE_NAME = 'Onboarding';

const createApplication = async (
    apiUrl: string,
    token: string,
    application: DraftApplication
) => {
    const response = await fetch(`${apiUrl}/applications`, {
        method: 'POST',
        headers: new Headers({
            authorization: `Bearer ${token}`,
            'content-type': 'application/json',
        }),
        body: JSON.stringify(application),
    });

    if (response.ok) {
        const {
            data: { id, name, type, icon },
        } = await response.json();
        const newApplication: Application = { id, name, type, icon };
        return newApplication;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;

    case 422:
        throw new Error('Application data invalid');
        break;
    }

    throw new Error(response.statusText);
};

const createSchedule = async (apiUrl: string, token: string, name: string) => {
    const response = await fetch(`${apiUrl}/schedules`, {
        method: 'POST',
        headers: new Headers({
            authorization: `Bearer ${token}`,
            'content-type': 'application/json',
        }),
        body: JSON.stringify({
            name,
            startHour: 0,
            startMinute: 0,
            endHour: 23,
            endMinute: 59,
            days: {
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
            },
        }),
    });

    if (response.ok) {
        const {
            data: {
                id,
                name,
                startHour,
                startMinute,
                endHour,
                endMinute,
                days: {
                    monday,
                    tuesday,
                    wednesday,
                    thursday,
                    friday,
                    saturday,
                    sunday,
                },
            },
        } = await response.json();
        const newSchedule: Schedule = {
            id,
            name,
            startHour,
            startMinute,
            endHour,
            endMinute,
            days: {
                monday,
                tuesday,
                wednesday,
                thursday,
                friday,
                saturday,
                sunday,
            },
        };
        return newSchedule;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;

    case 422:
        throw new Error('Schedule data invalid');
        break;
    }

    throw new Error(response.statusText);
};

const getContentApplicationId = async (
    apiUrl: string,
    token: string,
    content: DeviceContent
) => {
    if (content.type === DeviceContentType.IMAGE) {
        // Create image app
    } else if (content.type === DeviceContentType.VIDEO) {
        // Create video app
    } else if (content.type === DeviceContentType.URL) {
        const urlContent: UrlContent = content;

        // Find matching URL app
        const demoApplications = await getApplications(apiUrl, token, {
            name: ONBOARDING_APPLICATION_NAME,
            type: DeviceContentType.URL,
        });

        for (const demoApplication of demoApplications.filter(
            ({ name }) => name === ONBOARDING_APPLICATION_NAME
        )) {
            const { versions } = await getApplication(
                apiUrl,
                token,
                demoApplication.id
            );

            // @NOTE(adam): only consider applications with one version, there's no accurate way to determine the right version otherwise
            if (versions.length === 1 && versions[0].properties?.url === urlContent.url) {
                return demoApplication.id;
            }
        }

        // If none found, make it
        const application = await createApplication(apiUrl, token, {
            name: ONBOARDING_APPLICATION_NAME,
            type: ApplicationType.URL,
        });

        await createVersion(apiUrl, token, application.id, {
            version: '1.0.0',
            channel: VersionChannel.STABLE,
            status: VersionStatus.PUBLISHED,
            url: urlContent.url,
        });

        return application.id;
    } else if (content.type === DeviceContentType.APP) {
        const appContent: AppContent = content;
        return appContent.id;
    } else {
        throw new Error('Unknown content');
    }
};

export const createVersion = async (
    apiUrl: string,
    token: string,
    applicationId: number,
    version: DraftVersion
) => {
    const response = await fetch(
        `${apiUrl}/applications/${applicationId}/versions`,
        {
            method: 'POST',
            headers: new Headers({
                authorization: `Bearer ${token}`,
                'content-type': 'application/json',
            }),
            body: JSON.stringify(version),
        }
    );

    if (response.ok) {
        const {
            data: { id, applicationId, version, channel, status },
        } = await response.json();
        const newVersion: Version = {
            id,
            applicationId,
            version,
            channel,
            status,
        };
        return newVersion;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;

    case 422:
        throw new Error('Version data invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const enrolDevice = async (
    apiUrl: string,
    token: string,
    serial: string
) => {
    if (!token) {
        throw new Error('Unable to retrieve accounts - must log in first');
    }

    const response = await fetch(`${apiUrl}/devices/claim`, {
        method: 'POST',
        headers: new Headers({
            authorization: `Bearer ${token}`,
            'content-type': 'application/json',
        }),
        body: JSON.stringify({ serial }),
    });

    if (response.ok) {
        return;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const getApplication = async (
    apiUrl: string,
    token: string,
    id: number
) => {
    const url = `${apiUrl}/applications/${id}`;
    const response = await fetch(url, {
        headers: new Headers({ authorization: `Bearer ${token}` }),
    });

    if (response.ok) {
        const {
            data: application,
        }: { data: Application } = await response.json();
        return application;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const getApplications = async (
    apiUrl: string,
    token: string,
    filters?: Record<string, string>
) => {
    const url = new URL(`${apiUrl}/applications`);
    if (filters) {
        url.searchParams.append(
            'filter',
            Object.entries(filters)
                .map(pair => pair.join('='))
                .join(';')
        );
    }

    const response = await fetch(url.toString(), {
        headers: new Headers({ authorization: `Bearer ${token}` }),
    });

    if (response.ok) {
        const { data } = await response.json();
        const applications: Application[] = data.map(
            ({ id, name, type, icon }: Application) => ({
                id,
                name,
                type,
                icon,
            })
        );
        return applications;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const getDeviceDisplays = async (
    apiUrl: string,
    token: string,
    deviceId: number
) => {
    const response = await fetch(
        `${apiUrl}/devices/${deviceId}/displays?filter=disabled=false;primary=true`,
        {
            headers: new Headers({ authorization: `Bearer ${token}` }),
        }
    );

    if (response.ok) {
        const { data } = await response.json();
        const displays: Display[] = data.map(
            ({ id, deviceId, disabled, primary }: Display) => ({
                id,
                deviceId,
                disabled,
                primary,
            })
        );
        return displays;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const getSchedules = async (
    apiUrl: string,
    token: string,
    name: string
) => {
    const response = await fetch(`${apiUrl}/schedules?filter=name=${name}`, {
        headers: new Headers({ authorization: `Bearer ${token}` }),
    });

    if (response.ok) {
        const { data } = await response.json();
        const schedules: Schedule[] = data.map(
            ({
                id,
                name,
                startHour,
                startMinute,
                endHour,
                endMinute,
                days: {
                    monday,
                    tuesday,
                    wednesday,
                    thursday,
                    friday,
                    saturday,
                    sunday,
                },
            }: Schedule) => ({
                id,
                name,
                startHour,
                startMinute,
                endHour,
                endMinute,
                days: {
                    monday,
                    tuesday,
                    wednesday,
                    thursday,
                    friday,
                    saturday,
                    sunday,
                },
            })
        );
        return schedules;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const getUser = async (apiUrl: string, token: string) => {
    const response = await fetch(`${apiUrl}/users/me`, {
        headers: new Headers({ authorization: `Bearer ${token}` }),
    });
    if (response.ok) {
        const {
            data: { id, firstName, lastName, email, accounts },
        } = await response.json();
        const user: User = { id, firstName, lastName, email, accounts };
        return user;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const installApplication = async (
    apiUrl: string,
    token: string,
    applicationId: number,
    deviceId: number,
    checkAlreadyInstalled = true
) => {
    // @NOTE(adam): the RESTful API currently allows duplicate installations;
    // in the interim until that is fixed, these checks should always run
    if (checkAlreadyInstalled) {
        const url = new URL(`${apiUrl}/devices/${deviceId}/applications`);
        url.searchParams.append('filter', `id=${applicationId}`);

        try {
            const response = await fetch(url.toString(), {
                headers: new Headers({ authorization: `Bearer ${token}` }),
            });

            const { data } = await response.json();
            if (data.length) {
                return;
            }
        } catch {}
    }

    const response = await fetch(
        `${apiUrl}/devices/${deviceId}/applications/${applicationId}`,
        {
            headers: new Headers({ authorization: `Bearer ${token}` }),
            method: 'POST',
        }
    );

    if (response.ok) {
        return;
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const loadDeviceContent = async (
    apiUrl: string,
    token: string,
    deviceId: number,
    content: DeviceContent
) => {
    const applicationId = await getContentApplicationId(apiUrl, token, content);
    const schedules = await getSchedules(
        apiUrl,
        token,
        ONBOARDING_SCHEDULE_NAME
    );

    const existingSchedule = schedules[0];
    const schedule =
        existingSchedule ||
        (await createSchedule(apiUrl, token, ONBOARDING_SCHEDULE_NAME));

    await installApplication(apiUrl, token, applicationId, deviceId);

    const displays = await getDeviceDisplays(apiUrl, token, deviceId);
    if (!displays.length) {
        throw new DisplayMissingError('Device has no available displays');
    }

    // @NOTE(adam): only need to check if scheduled when using an existing schedule
    const checkAlreadyScheduled = !!existingSchedule;

    await scheduleApplication(
        apiUrl,
        token,
        applicationId,
        deviceId,
        schedule.id,
        displays[0].id,
        checkAlreadyScheduled
    );
};

export const logIn = async (
    apiUrl: string,
    email: string,
    password: string
) => {
    const response = await fetch(`${apiUrl}/auth/login`, {
        method: 'post',
        body: JSON.stringify({ email, password }),
        headers: new Headers({ 'content-type': 'application/json' }),
    });

    if (response.ok) {
        const {
            data: { expiresAt, token, account },
        } = await response.json();
        return { expiresAt, token, accountId: account };
    }

    switch (response.status) {
    case 401:
        throw new Error('Login credentials invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const scheduleApplication = async (
    apiUrl: string,
    token: string,
    applicationId: number,
    deviceId: number,
    scheduleId: number,
    displayId: number,
    checkAlreadyScheduled = true
) => {
    // Check app already scheduled on device
    if (checkAlreadyScheduled) {
        const getSchedulesResponse = await fetch(
            `${apiUrl}/devices/${deviceId}/displays/${displayId}/applications`,
            { headers: new Headers({ authorization: `Bearer ${token}` }) }
        );

        const { data: schedules } = await getSchedulesResponse.json();
        const alreadyScheduled = schedules.some(
            ({
                application,
                schedule,
            }: {
                application: Application;
                schedule: Schedule;
            }) => application.id === applicationId && schedule.id === scheduleId
        );

        if (alreadyScheduled) {
            return;
        }
    }

    const createScheduleResponse = await fetch(
        `${apiUrl}/devices/${deviceId}/displays/${displayId}/applications`,
        {
            headers: new Headers({
                authorization: `Bearer ${token}`,
                'content-type': 'application/json',
            }),
            method: 'POST',
            body: JSON.stringify({ applicationId, scheduleId }),
        }
    );

    if (createScheduleResponse.ok) {
        return;
    }

    switch (createScheduleResponse.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;
    }

    throw new Error(createScheduleResponse.statusText);
};

export const switchToAccount = async (
    apiUrl: string,
    token: string,
    accountId: number
) => {
    const response = await fetch(`${apiUrl}/auth/switch/${accountId}`, {
        headers: new Headers({ authorization: `Bearer ${token}` }),
    });

    if (response.ok) {
        const {
            data: { expiresAt, token, account },
        } = await response.json();
        return { expiresAt, token, accountId: account };
    }

    switch (response.status) {
    case 401:
        throw new Error('Credentials invalid');
        break;
    }

    throw new Error(response.statusText);
};

export const validateToken = async (
    apiUrl: string,
    token: string
) => {
    const response = await fetch(`${apiUrl}/auth/validate`, {
        headers: new Headers({ authorization: `Bearer ${token}` }),
    });

    if (response.ok) {
        const responseData = await response.json();
        return !!responseData.data?.valid;
    }

    return false;
};
