import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';

import { AuthService } from './services/auth';
import { BluetoothService } from './services/bluetooth';
import { MeldService } from './services/meld';
import { ReplayService } from './services/replay';
import App from './containers/App';

const apiUrl = process.env.API_URL || 'https://canary.api.meld.cx/v1';

ReactDOM.render(
    <BluetoothService>
        <AuthService apiUrl={apiUrl}>
            <MeldService apiUrl={apiUrl}>
                <ReplayService>
                    <Router>
                        <App />
                    </Router>
                </ReplayService>
            </MeldService>
        </AuthService>
    </BluetoothService>,
    document.getElementById('root')
);
