import React, { useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { Account } from '../../types/account';
import { useAuth } from '../../services/auth';
import { useBluetooth } from '../../services/bluetooth';
import { useMeld } from '../../services/meld';
import { useReplay } from '../../services/replay';
import Button from '../../components/Button';
import Content from '../../components/Content';
import Enrolled from '../Enrolled';
import Item from '../../components/List/Item';
import List from '../../components/List';
import cloud from './cloud.svg';

interface Props {
    onEnrolled: () => void;
}

enum Status {
    PENDING,
    ENROLLING,
    ENROLLED,
    FAILED,
}

const Enrol: React.FC<Props> = ({ onEnrolled }) => {
    const accounts = useAuth().user?.accounts;
    const { getDeviceIdentifiers } = useBluetooth();
    const { enrolDevice } = useMeld();
    const { rememberAccountId } = useReplay();

    const [status, setStatus] = useState<Status>(Status.PENDING);
    const [selectedAccount, setSelectedAccount] = useState<Account>();
    const [notice, setNotice] = useState('');

    const onEnrol = async () => {
        setStatus(Status.ENROLLING);

        try {
            const { serial } = await getDeviceIdentifiers();
            await enrolDevice(serial, selectedAccount);
            rememberAccountId(selectedAccount.id);
            setStatus(Status.ENROLLED);
        } catch (e) {
            throw e;
            setNotice(e.message);
            setStatus(Status.FAILED);
        }
    };

    if (status === Status.ENROLLED) {
        return <Enrolled account={selectedAccount} onNext={onEnrolled} />;
    }

    return (
        <Wrap data-testid={IS_TESTING && 'enrol'}>
            <ScrollArea>
                <Hero src={cloud} />
                <h1>
                    <small>Step 4:</small>
                    Add device into account
                </h1>

                {notice && (
                    <Notice data-testid={IS_TESTING && 'notice'}>
                        {notice}
                    </Notice>
                )}

                <AccountList>
                    {!!accounts &&
                        accounts.map((account: Account) => (
                            <AccountItem
                                className={classnames({
                                    active: selectedAccount?.id === account.id,
                                    disabled: status === Status.ENROLLING,
                                })}
                                data-testid={IS_TESTING && 'account-option'}
                                key={account.id}
                                onClick={() => setSelectedAccount(account)}
                            >
                                <img src={account.icon} />
                                <span>{account.name}</span>
                            </AccountItem>
                        ))}
                </AccountList>
            </ScrollArea>

            <Button
                testId="enrol-button"
                onClick={
                    selectedAccount && status !== Status.ENROLLING
                        ? onEnrol
                        : undefined
                }
            >
                {status === Status.ENROLLING ? 'Adding...' : 'Add'}
            </Button>
        </Wrap>
    );
};

export default Enrol;

const AccountList = styled(List)`
    text-align: left;
    flex: 1 0;
    width: 100%;
`;

const AccountItem = styled(Item)`
    cursor: pointer;
    user-select: none;

    &.disabled {
        cursor: default;
        opacity: 0.5;
    }

    img {
        border: 0 none;
        flex: 0 0 auto;
        height: 2rem;
        margin-right: 1rem;
        width: 2rem;
        border-radius: 0.5rem;
    }
`;

const Hero = styled.img`
    height: 4rem;
`;

const Notice = styled.p`
    color: var(--warning);
`;

const ScrollArea = styled.div`
    flex: 1 1;
    overflow: auto;
    margin: -1rem -2rem 1rem;
    padding: 1rem 2rem 0;
`;

const Wrap = styled(Content)`
    h1 {
        margin-bottom: 1.5rem;
    }
`;
