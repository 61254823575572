import { MdiReactIconComponentType } from 'mdi-react';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CircleOutlineIcon from 'mdi-react/CircleOutlineIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import DotsHorizontalCircleIcon from 'mdi-react/DotsHorizontalCircleIcon';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReplayTask, TaskStatus } from '../../hooks/useReplayAPI';
import { useBluetooth } from '../../services/bluetooth';
import { useReplay } from '../../services/replay';
import Button from '../../components/Button';
import Content from '../../components/Content';
import rocket from './rocket.svg';

interface Props {
    onDone: () => void;
}

const icons: Record<TaskStatus, MdiReactIconComponentType> = {
    [TaskStatus.DONE]: CheckCircleIcon,
    [TaskStatus.FAILED]: CloseCircleIcon,
    [TaskStatus.PENDING]: CircleOutlineIcon,
    [TaskStatus.RUNNING]: DotsHorizontalCircleIcon,
};

const Replicate: React.FC<Props> = ({ onDone }) => {
    const { replay, tasks } = useReplay();
    const { device } = useBluetooth();
    const [completed, setCompleted] = useState(false);
    const [failed, setFailed] = useState(false);

    useEffect(() => {
        replay()
            .then(() => {
                setCompleted(true);
            })
            .catch(() => {
                setFailed(true);
            });
    }, []);

    return (
        <Wrap data-testid={IS_TESTING && 'replicate'}>
            <img src={rocket} />
            <h1>
                Replicating Setup
                {device?.name ? ` to '${device.name}'` : ''}
            </h1>
            <Tasks>
                {tasks.map(({ label, status }: ReplayTask, t: number) => {
                    const Icon = icons[status];
                    return (
                        <Task key={t} className={status}>
                            <Icon /> {label}
                        </Task>
                    );
                })}
            </Tasks>
            <Button
                testId="continue-button"
                onClick={completed || failed ? onDone : undefined}
            >
                {failed ? 'Back' : 'Continue'}
            </Button>
        </Wrap>
    );
};

export default Replicate;

const Task = styled.li`
    list-style: none;
    margin: 0 0 0.5rem;
    padding: 0;
    display: flex;
    justify-content: center;
    text-align: left;

    svg {
        margin-right: 0.5rem;
    }

    &.${TaskStatus.DONE} {
        color: var(--success);
    }

    &.${TaskStatus.FAILED} {
        color: var(--warning);
    }

    &.${TaskStatus.PENDING} {
        opacity: 0.5;
    }

    &.${TaskStatus.RUNNING} {
        font-weight: bold;
    }
`;

const Tasks = styled.ul`
    margin: 0 0 1rem;
    padding: 0;
`;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 0 1rem;
        max-width: 12rem;
    }
`;
