import styled from 'styled-components';

const Item = styled.li`
    align-items: center;
    background: var(--foreground);
    border-radius: 0.5rem;
    border: 0 none;
    box-sizing: border-box;
    color: var(--background);
    cursor: pointer;
    display: flex;
    font-size: ${15 / 16}rem;
    font: inherit;
    height: 3.5rem;
    margin: 0.25rem;
    max-width: var(--item-width);
    padding: 0.125rem 1rem;
    width: 100%;
    
    &.active {
        background: var(--warning);
        color: var(--foreground);
    }

    &.clickable {
        cursor: pointer;
    }

    > * {
      flex: 1 0 auto;
    }
`;

export default Item;
