import React from 'react';
import styled from 'styled-components';

import Button, { ButtonWrap } from '../../components/Button';
import Content from '../../components/Content';
import rocket from './rocket.svg';

interface Props {
    onSetupNewDevice: () => void;
    onReplicate: () => void;
    warning?: string;
}

const Completed: React.FC<Props> = ({ onReplicate, onSetupNewDevice, warning }) => (
    <Wrap data-testid={IS_TESTING && 'completed'}>
        <img src={rocket} />
        <h1>Device setup completed</h1>
        {!!warning && <Warning>Not able to fully complete content loading. {warning}</Warning>}

        <Button testId="setup-new-device-button" onClick={onSetupNewDevice}>
            Set up new canvas
        </Button>
        <p>Set up canvas from scratch</p>

        <Button
            background="var(--secondary)"
            color="var(--foreground)"
            testId="replicate-setup-button"
            onClick={onReplicate}
        >
            Replicate setup
        </Button>
        <p>Copy this setup to another canvas</p>
    </Wrap>
);

export default Completed;

const Warning = styled.p`
    color: var(--warning);
`;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 0 1rem;
        max-width: 12rem;
    }

    ${ButtonWrap} {
        margin-top: 1rem;
    }
`;
