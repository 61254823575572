import { useEffect, useState } from 'react';

import { getBluetoothAvailability, getMeldDevice } from '../helpers/bluetooth';

export interface BluetoothAPI {
    isAvailable: boolean;
    getDevice?: () => Promise<BluetoothDevice>;
}

export interface AvailableBluetoothAPI extends BluetoothAPI {
    getDevice: () => Promise<BluetoothDevice>;
}

export interface UnavailableBluetoothAPI extends BluetoothAPI {}

export default () => {
    const [isAvailable, setIsAvailable] = useState(false);
    useEffect(() => {
        const subscription = getBluetoothAvailability().subscribe(
            setIsAvailable
        );
        return () => subscription.unsubscribe();
    }, []);

    const api: BluetoothAPI = {
        getDevice: isAvailable ? getMeldDevice : undefined,
        isAvailable,
    };
    return api;
};
