import React from 'react';
import styled from 'styled-components';

import { WifiNetwork, WifiStatus } from '../../types/network';
import { useBluetooth } from '../../services/bluetooth';
import { useReplay } from '../../services/replay';
import Button, { ButtonWrap } from '../../components/Button';
import Content from '../../components/Content';
import Field, { FieldWrap } from '../../components/Field';
import Input from '../../components/Input';
import helmet from '../SelectNetwork/helmet.svg';
import useInputValue from '../../hooks/useInputValue';

interface Props {
    network: WifiNetwork;
    onCancel: () => void;
    onConnected: () => void;
}

const ConnectToNetwork: React.FC<Props> = ({
    network,
    onCancel,
    onConnected,
}) => {
    const { rememberNetwork, rememberPassphrase } = useReplay();
    const { joinWifiNetwork, joinStatus } = useBluetooth();
    const password = useInputValue('');

    const onConnect = async () => {
        try {
            const connectedNetwork = await joinWifiNetwork(network, password.value);
            rememberNetwork(connectedNetwork);
            rememberPassphrase(password.value);
            onConnected();
        } catch {
        }
    };

    return (
        <Wrap data-testid={IS_TESTING && 'connect-to-network'}>
            <img src={helmet} />
            <h1>
                Connect to <small>&lsquo;{network.ssid}&rsquo;</small>
            </h1>
            {joinStatus === WifiStatus.CONNECTED && (
                <Notice data-testid={IS_TESTING && 'status-connected'}>
                    Connected!
                </Notice>
            )}
            {joinStatus === WifiStatus.CONNECTING && (
                <Notice data-testid={IS_TESTING && 'status-connecting'}>
                    Connecting...
                </Notice>
            )}
            {joinStatus === WifiStatus.UNAUTHENTICATED && (
                <Notice data-testid={IS_TESTING && 'status-failed'}>
                    Unable to connect - check credentials and try again
                </Notice>
            )}

            <Field placeholder="Enter password">
                <Input
                    data-testid={IS_TESTING && 'password'}
                    disabled={joinStatus === WifiStatus.CONNECTING}
                    readOnly={joinStatus === WifiStatus.CONNECTING}
                    type="password"
                    {...password}
                />
            </Field>
            <Buttons>
                <Button
                    background="var(--secondary)"
                    color="var(--foreground)"
                    onClick={onCancel}
                    testId="cancel"
                >
                    Cancel
                </Button>
                <Button
                    testId="connect"
                    onClick={
                        !!password.value && joinStatus !== WifiStatus.CONNECTING
                            ? onConnect
                            : undefined
                    }
                >
                    Connect
                </Button>
            </Buttons>
        </Wrap>
    );
};

export default ConnectToNetwork;

const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;

    ${ButtonWrap} {
        min-width: 0;

        + ${ButtonWrap} {
            margin-left: 1rem;
        }
    }
`;

const Notice = styled.p`
    color: var(--success);
    margin: 0;
`;

const Wrap = styled(Content)`
    flex: 1 0 auto;

    h1 {
        margin-bottom: 0;
        text-align: center;

        small {
            text-transform: none;
            font-size: 0.75em;
            margin-top: 0.25rem;
        }
    }

    input[disabled] {
        opacity: 0.5;
    }

    img {
        flex: 0 0 auto;
        margin: 0 0.5rem 1rem;
        height: auto;
        width: 8rem;
    }

    ${FieldWrap} {
        margin: 1rem 0 1.5rem;
    }
`;
