import React from 'react';

import Button from '../../components/Button';
import Content from '../../components/Content';

interface Props {
    onNext: () => void;
}

const Welcome: React.FC<Props> = ({ onNext }) => (
    <Content data-testid={IS_TESTING && 'welcome'}>
        <h1>Let&apos;s enrol a new device</h1>
        <Button testId="next-button" onClick={onNext}>Start</Button>
    </Content>
);

export default Welcome;
