import React, { useState } from 'react';
import styled from 'styled-components';

import { useBluetooth } from '../../services/bluetooth';
import Button from '../../components/Button';
import Content from '../../components/Content';
import astronaut from './astronaut.svg';

interface Props {
    onPaired: () => void;
}

const Pair: React.FC<Props> = ({ onPaired }) => {
    const [loading, setLoading] = useState(false);
    const { loadDevice } = useBluetooth();
    const onPair = async () => {
        try {
            setLoading(true);
            await loadDevice();
            onPaired();
        } catch {
            setLoading(false);
        }
    };

    return (
        <Wrap data-testid={IS_TESTING && 'pair'}>
            <img src={astronaut} />
            <h1>
                <small>Step 2:</small> Select a device to set up
            </h1>
            <Button testId="pair-pick-device" onClick={loading ? undefined : onPair}>
                {loading ? 'Connecting...' : 'Pick Device'}
            </Button>
        </Wrap>
    );
};

export default Pair;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 0.5rem 1rem;
        height: auto;
        width: 100%;
        max-height: 20rem;
        max-width: 12rem;
    }
`;
