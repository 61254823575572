import React from 'react';
import styled from 'styled-components';

import Button, { ButtonWrap } from '../../components/Button';
import Content from '../../components/Content';
import astronaut from './astronaut.svg';

interface Props {
    onNext: () => void;
    onSkip: () => void;
}

const ShowContent: React.FC<Props> = ({ onNext, onSkip }) => (
    <Wrap data-testid={IS_TESTING && 'show-content'}>
        <img src={astronaut} />
        <h1>
            <small>Step 5:</small>
            Show content on device
        </h1>
        <Buttons>
            <Button
                background="var(--secondary)"
                color="var(--foreground)"
                testId="skip-button"
                onClick={onSkip}
            >
                Skip
            </Button>
            <Button testId="next-button" onClick={onNext}>
                Continue
            </Button>
        </Buttons>
    </Wrap>
);

export default ShowContent;

const Buttons = styled.div`
    display: flex;
    margin-top: 1rem;
    width: 100%;

    ${ButtonWrap} {
        flex: 2 1 0;
        min-width: 0;

        + ${ButtonWrap} {
            flex: 3 1 0;
            margin-left: 1rem;
        }
    }
`;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 0 1rem;
        max-width: 12rem;
    }
`;
