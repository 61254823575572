import { Account } from '../types/account';
import { Application } from '../types/application';
import { DeviceContent } from '../types/content';
import { MeldDevice } from '../types/device';
import {
    enrolDevice,
    getApplications,
    loadDeviceContent,
} from '../helpers/meld';
import { useAuth } from '../services/auth';

export interface MeldAPI {
    enrolDevice: (device: MeldDevice, account: Account) => Promise<void>;
    getApplications: () => Promise<Application[]>;
    loadContent: (serial: string, deviceContent: DeviceContent) => void;
}

interface Props {
    apiUrl: string;
    mock?: Partial<MeldAPI>;
}

export default ({ apiUrl, mock }: Props) => {
    const { token } = useAuth();
    if (!token) {
        return {};
    }

    const api: MeldAPI = {
        enrolDevice: enrolDevice.bind(null, apiUrl, token),
        getApplications: getApplications.bind(null, apiUrl, token),
        loadContent: loadDeviceContent.bind(null, apiUrl, token),
        ...mock,
    };

    return api;
};
