import React from 'react';
import styled from 'styled-components';

import Button from '../../components/Button';
import Content from '../../components/Content';
import connect from './connect.svg';

interface Props {
    onNext: () => void;
}

const Paired: React.FC<Props> = ({ onNext }) => (
    <Wrap data-testid={IS_TESTING && 'paired'}>
        <img src={connect} />
        <h1>Securely connected!</h1>
        <Button testId="paired-next" onClick={onNext}>
            Continue
        </Button>
    </Wrap>
);

export default Paired;

const Wrap = styled(Content)`
    img {
        flex: 0 1 auto;
        margin: 0 -2rem 1rem;
    }
`;
