import { useEffect, useState } from 'react';
import store from 'store';

import { User } from '../types/user';
import { getUser, logIn, switchToAccount, validateToken } from '../helpers/meld';

export interface AuthAPI extends Partial<AuthState> {
    logIn: (email: string, password: string) => Promise<void>;
    logOut: () => void;
    switchToAccount?: (accountId: number) => Promise<void>;
}

export interface AuthState {
    accountId: number;
    expiresAt: number;
    token: string;
    user: User;
}

interface Props {
    apiUrl: string;
    mock?: Partial<AuthAPI>;
}

export default ({ apiUrl, mock }: Props) => {
    const [authState, setAuthState] = useState<AuthState | undefined>();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const authState = store.get('authState');
        if (!authState) {
            setReady(true);
            return;
        }

        const restoreSession = async () => {
            // @TODO(adam): need to attempt to renew token when restoring
            if (authState.expiresAt < Date.now() / 1000) {
                store.remove('authState');
                setReady(true);
                return;
            }

            const isValid = await validateToken(apiUrl, authState.token);
            if (!isValid) {
                store.remove('authState');
                setReady(true);
                return;
            }

            setAuthState(authState);
            setReady(true);
        };

        restoreSession();
    }, []);

    const switchToAccountAndRefresh = async (accountId: number) => {
        const {
            token: newToken,
            expiresAt,
            accountId: newAccountId,
        } = await switchToAccount(apiUrl, authState.token, accountId);
        const newAuthState: AuthState = {
            ...authState,
            token: newToken,
            expiresAt,
            accountId: newAccountId,
        };
        store.set('authState', newAuthState);
        setAuthState(newAuthState);
    };

    const api: AuthAPI = {
        logIn: async (email: string, password: string) => {
            const { accountId, token, expiresAt } = await logIn(apiUrl, email, password);
            const authState: AuthState = {
                accountId,
                token,
                user: await getUser(apiUrl, token),
                expiresAt,
            };
            store.set('authState', authState);
            setAuthState(authState);
        },
        logOut: () => {
            store.remove('authState');
            setAuthState(undefined);
        },
        switchToAccount: authState?.token && switchToAccountAndRefresh,
        ...authState,
        ...mock,
    };

    return ready ? api : undefined;
};
