export const MELD_DEVICE_UUID = '00001825-0000-1000-8000-00805f9b34fb';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, flatMap, map, startWith } from 'rxjs/operators';

import {
    BluetoothRequest,
    BluetoothResponse,
    SendMethod,
} from '../types/bluetooth';

export const MELD_READ_CHARACTERISTIC_ID =
    '00002aba-0000-1000-8000-00805f9b34fb';
export const MELD_WRITE_CHARACTERISTIC_ID =
    '00002abb-0000-1000-8000-00805f9b34fb';

export const START_MARKER = '|||ECE25400-BAF8-4AA2|||';
export const END_MARKER = '|||819A-D9EA793991E0|||';
const MAX_TRANSMISSION_SIZE = 20;

export const call = <T>(
    send: SendMethod,
    requestId: number,
    command: string,
    data: T
) => {
    const message: BluetoothRequest<T> = {
        id: requestId,
        command,
        data,
    };
    return send(message);
};

export const callCharacteristic = async (
    getService: () => {
        getCharacteristic: (
            id: string
        ) => { writeValue: (value: BufferSource) => Promise<void> };
    },
    id: string
) => {
    const service = await getService();
    const characteristic = await service.getCharacteristic(id);
    return await characteristic.writeValue(Uint8Array.from([1]));
};

export const from = <T, R>(
    send: SendMethod,
    requestId: number,
    command: string,
    data: T,
    responses$: Observable<BluetoothResponse>,
    dataType?: string
) => {
    const message: BluetoothRequest<T> = {
        id: requestId,
        command,
        data,
    };
    send(message);
    return responses$.pipe(
        filter(
            (response: BluetoothResponse<R>) =>
                response.id === requestId &&
                (!dataType || dataType === response.dataType)
        ),
        map((response: BluetoothResponse<R>) => response.data)
    );
};
export const getBluetoothAvailability = () => {
    const availability$ = new BehaviorSubject<boolean>(false);
    if (!window.navigator.bluetooth) {
        return availability$;
    }

    // Listen for updates
    const update$ = new Observable(subscriber => {
        window.navigator.permissions
            .query({ name: 'bluetooth' })
            .then(bluetoothStatus => {
                bluetoothStatus.onchange = value => subscriber.next(value);
            })
            .catch(error => subscriber.error(error));
    });

    // Turn updates into availability changes
    update$
        .pipe(
            startWith(false),
            flatMap(() => window.navigator.bluetooth.getAvailability())
        )
        .subscribe({
            next: state => availability$.next(state),
            error: () => availability$.next(true),
        });

    return availability$;
};

export const getCharacteristicValue = async (
    getService: () => {
        getCharacteristic: (
            id: string
        ) => { readValue: () => Promise<DataView> };
    },
    id: string,
    type: 'number' | 'string' = 'string'
) => {
    const service = await getService();
    const characteristic = await service.getCharacteristic(id);
    const value = await characteristic.readValue();

    if (type === 'number') {
        return value.getUint8(0);
    }

    const decoder = new TextDecoder('utf-8');
    return decoder.decode(value);
};

export const request = async <T, R>(
    send: SendMethod,
    requestId: number,
    command: string,
    data: T,
    responses$: Observable<BluetoothResponse>,
    dataType?: string
) => {
    const message: BluetoothRequest<T> = {
        id: requestId,
        command,
        data,
    };
    const response = new Promise<R>((resolve, reject) => {
        responses$
            .pipe(
                first(
                    (response: BluetoothResponse) =>
                        response.id === requestId &&
                        (!dataType || dataType === response.dataType)
                )
            )
            .subscribe({
                next: (response: BluetoothResponse<R>) =>
                    resolve(response.data),
                error: reject,
            });
    });
    await send(message);
    return await response;
};

export const send = <T>(
    request: BluetoothRequest<T>,
    queue$: Subject<() => Promise<void>>,
    inputCharacteristic: BluetoothRemoteGATTCharacteristic
) => {
    const encoder = new TextEncoder();
    const sendFullMessage = async () => {
        const messageContent =
            START_MARKER + JSON.stringify(request) + END_MARKER;
        for (
            let start = 0;
            start < messageContent.length;
            start += MAX_TRANSMISSION_SIZE
        ) {
            const part = messageContent.substr(start, MAX_TRANSMISSION_SIZE);
            await inputCharacteristic.writeValue(encoder.encode(part).buffer);
        }
    };
    return new Promise<void>((resolve, reject) => {
        queue$.next(() => sendFullMessage().then(resolve).catch(reject));
    });
};

export const setCharacteristicValue = async (
    getService: () => {
        getCharacteristic: (
            id: string
        ) => { writeValue: (value: BufferSource) => Promise<void> };
    },
    id: string,
    value: string | number
) => {
    const service = await getService();
    const characteristic = await service.getCharacteristic(id);

    if (typeof value === 'string') {
        const encoder = new TextEncoder();
        return await characteristic.writeValue(encoder.encode(value).buffer);
    } else if (typeof value === 'number') {
        return await characteristic.writeValue(Uint8Array.from([value]).buffer);
    }

    throw new Error(
        `Unable to set characteristic '${id}' value to '${value}' - value must be a string or number`
    );
};

export const getMeldDevice = (
    bluetoothApi: Pick<Bluetooth, 'requestDevice'> = window.navigator.bluetooth
) =>
    bluetoothApi.requestDevice({
        filters: [{ services: [MELD_DEVICE_UUID] }],
    });

export const getGatt = async <
    D extends {
        gatt?: { connected: boolean; connect: () => Promise<any> };
    } = BluetoothDevice
>(
    device: D
) => {
    if (!device.gatt) {
        throw new Error('Bluetooth GATT service not available');
    }

    if (!device.gatt.connected) {
        await device.gatt.connect();
    }

    return device.gatt;
};

export const getService = async (
    getGatt: () => Promise<Pick<BluetoothRemoteGATTServer, 'getPrimaryService'>>
) => {
    const gatt = await getGatt();
    return await gatt.getPrimaryService(MELD_DEVICE_UUID);
};
