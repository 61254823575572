import { Subscription } from 'rxjs';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { WifiNetwork } from '../../types/network';
import { useBluetooth } from '../../services/bluetooth';
import Button from '../../components/Button';
import Content from '../../components/Content';
import Item from '../../components/List/Item';
import List from '../../components/List';
import Signal, { BarWrap, SignalWrap } from '../../components/Signal';
import helmet from './helmet.svg';

interface Props {
    onSelect: (network: WifiNetwork) => void;
}

const SelectNetwork: React.FC<Props> = ({ onSelect }) => {
    const api = useBluetooth();
    const [highlightedNetwork, setHighlightedNetwork] = useState<
        WifiNetwork | undefined
    >();
    const { networks, searchForWifiNetworks } = api;
    useEffect(() => {
        let subscription: Subscription;
        searchForWifiNetworks().then((newSubscription: Subscription) => {
            subscription = newSubscription;
        });
        return () => subscription?.unsubscribe();
    }, []);

    const onHighlight = (network: WifiNetwork) => {
        setHighlightedNetwork(
            highlightedNetwork?.ssid === network.ssid ? undefined : network
        );
    };

    return (
        <Wrap data-testid={IS_TESTING && 'select-network'}>
            <ScrollArea>
                <img src={helmet} />
                <h1>
                    <small>Step 3:</small> Connect device to internet
                </h1>
                {!networks.length && (
                    <Loading>Searching for Wifi networks...</Loading>
                )}
                {!!networks.length && (
                    <List>
                        {networks.map((network: WifiNetwork) => (
                            <Item
                                data-testid={
                                    IS_TESTING && 'select-network-option'
                                }
                                className={classnames({
                                    active:
                                        network.ssid ===
                                        highlightedNetwork?.ssid,
                                    clickable: true,
                                })}
                                onClick={() => onHighlight(network)}
                                key={network.ssid}
                            >
                                <span>{network.ssid}</span>
                                <Signal strength={network.signalStrength} />
                            </Item>
                        ))}
                    </List>
                )}
            </ScrollArea>
            <Button
                testId="select-network-confirm"
                onClick={
                    highlightedNetwork
                        ? () => onSelect(highlightedNetwork)
                        : undefined
                }
            >
                Select
            </Button>
        </Wrap>
    );
};

export default SelectNetwork;

const Loading = styled.div`
    flex: 1 0 auto;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ScrollArea = styled.div`
    flex: 1 1;
    overflow: auto;
    margin: -1rem -2rem 1rem;
    padding: 1rem 2rem 0;
`;

const Wrap = styled(Content)`
    max-height: 100%;

    h1 {
        margin-top: 0.75rem;
    }

    img {
        height: 4rem;
        margin-top: 1rem;
    }

    ${List} {
        margin-bottom: 1rem;
        width: 100%;
        flex: 1 0;
        text-align: left;
    }

    ${Item}.active ${BarWrap} {
        background: #fff;
        opacity: 0.5;

        &.active {
            opacity: 1;
        }
    }

    ${SignalWrap} {
        flex: 0 0 auto;
    }
`;
