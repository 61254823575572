import React, { useState } from 'react';
import styled from 'styled-components';

import { useAuth } from '../../services/auth';
import Button, { ButtonWrap } from '../../components/Button';
import Content from '../../components/Content';
import Field, { FieldWrap } from '../../components/Field';
import Input from '../../components/Input';
import useInputValue from '../../hooks/useInputValue';

interface Props {
    onLogIn: () => void;
}

const LogIn: React.FC<Props> = ({ onLogIn }) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const email = useInputValue('');
    const password = useInputValue('');
    const { logIn } = useAuth();

    const onAttemptLogin = async () => {
        try {
            setLoading(true);
            await logIn(email.value, password.value);
            onLogIn();
        } catch (e) {
            setLoading(false);
            setError(e.message);
        }
    };

    return (
        <Wrap data-testid={IS_TESTING && 'log-in'}>
            <h1>
                <small>Step 1:</small> Log in to your meld account
            </h1>
            {loading && (
                <Notice data-testid={IS_TESTING && 'log-in-loading'}>
                    Loading...
                </Notice>
            )}
            {!loading && !!error && (
                <Notice data-testid={IS_TESTING && 'log-in-error'}>
                    {error}
                </Notice>
            )}

            <Field placeholder="Email address">
                <Input
                    data-testid={IS_TESTING && 'log-in-email'}
                    type="email"
                    {...email}
                />
            </Field>
            <Field placeholder="Password">
                <Input
                    data-testid={IS_TESTING && 'log-in-password'}
                    type="password"
                    {...password}
                />
            </Field>
            <Button testId="log-in-button" onClick={onAttemptLogin}>
                Log In
            </Button>
        </Wrap>
    );
};

export default LogIn;

const Notice = styled.p`
    color: var(--warning);
`;

const Wrap = styled(Content)`
    h1 {
        margin-bottom: 1.5rem;
    }

    ${FieldWrap} {
        margin: 0 0 0.5rem 0;
    }

    ${ButtonWrap} {
        margin-top: 1.5rem;
    }
`;
