import { useEffect, useRef, useState } from 'react';
import createService from '@adamdickinson/react-service';

import {
    BluetoothDeviceAPI,
} from '../types/bluetooth';
import useBluetoothAPI, {
    AvailableBluetoothAPI,
    UnavailableBluetoothAPI
} from '../hooks/useBluetoothAPI';
import useBluetoothDeviceAPI from '../hooks/useBluetoothDeviceAPI';

type UnavailableBluetoothServiceAPI = UnavailableBluetoothAPI;

interface AvailableBluetoothServiceAPI extends AvailableBluetoothAPI {
    device: BluetoothDevice;
    loadDevice: () => Promise<void>;
}

type ConnectedBluetoothServiceAPI = AvailableBluetoothServiceAPI &
    BluetoothDeviceAPI;

export type BluetoothServiceAPI =
    | UnavailableBluetoothServiceAPI
    | AvailableBluetoothServiceAPI
    | ConnectedBluetoothServiceAPI;

interface Props {
    mock?: BluetoothServiceAPI;
}

const useBluetoothServiceAPI = ({ mock }: Props = {}) => {
    const [device, setDevice] = useState<BluetoothDevice | undefined>();
    const bluetoothApi = useBluetoothAPI();
    const bluetoothDeviceApi = useBluetoothDeviceAPI(device);
    const deviceChanged = useRef<() => void>();

    useEffect(() => {
        if (bluetoothDeviceApi.loaded && deviceChanged.current) {
            deviceChanged.current();
            deviceChanged.current = undefined;
        }
    }, [bluetoothDeviceApi.loaded]);

    const api: BluetoothServiceAPI = {
        device,
        loadDevice: () =>
            new Promise(async (resolve, reject) => {
                try {
                    setDevice(await api.getDevice());
                    deviceChanged.current = resolve;
                } catch (error) {
                    reject(error);
                }
            }),
        ...bluetoothApi,
        ...bluetoothDeviceApi,
        ...mock,
    };

    return api;
};

const [BluetoothService, useBluetooth] = createService(useBluetoothServiceAPI);

export { BluetoothService, useBluetooth };
