import { Version } from './version';

export enum ApplicationType {
    PACKAGED = 'packaged',
    URL = 'url',
}

export interface Application {
    id: number;
    name: string;
    icon: string;
    type: ApplicationType;
    versions?: Version[];
}

export interface DraftApplication {
    name: string;
    type: ApplicationType;
}
