import { map } from 'rxjs/operators';

import { SingleCharBluetoothDeviceInterface as BluetoothDeviceInterface } from '../types/bluetooth';
import { DeviceIdentifiers } from '../types/device';
import { WifiNetwork, WifiStatus } from '../types/network';

export const getDeviceIdentifiers = (
    deviceInterface: BluetoothDeviceInterface
) =>
    deviceInterface.request<{}, DeviceIdentifiers>(
        'getDeviceIdentifiers',
        {},
        'deviceIdentifiers'
    );

enum ConnectionStatus {
    DISCONNECTED = 'Not Connected',
    CONNECTING = 'Connecting',
    CONNECTED = 'Connected',
    UNAVAILABLE = 'Unavailable',
}

const WIFI_STATUSES: Record<ConnectionStatus, WifiStatus> = {
    [ConnectionStatus.DISCONNECTED]: WifiStatus.DISCONNECTED,
    [ConnectionStatus.CONNECTING]: WifiStatus.CONNECTING,
    [ConnectionStatus.CONNECTED]: WifiStatus.CONNECTED,
    [ConnectionStatus.UNAVAILABLE]: WifiStatus.UNAUTHENTICATED,
};

type JoinWifiNetworkResponseData = { connectionStatus: ConnectionStatus };

type JoinWifiNetworkRequestData =
    | { bssid: string; passphrase: string }
    | { ssid: string; passphrase: string };

export const joinWifiNetwork = (
    deviceInterface: BluetoothDeviceInterface,
    requestData: JoinWifiNetworkRequestData
) =>
    deviceInterface
        .from<JoinWifiNetworkRequestData, JoinWifiNetworkResponseData>(
            'connectWifi',
            { bssid: '', ...requestData }
        )
        .pipe(
            map(
                (data: JoinWifiNetworkResponseData) =>
                    WIFI_STATUSES[data.connectionStatus]
            )
        );

type GetWifiNetworksRequestData = { numberOfNetworks?: number };

type GetWifiNetworksResponseData = {
    numberOfNetworks: number;
    networks: WifiNetwork[];
};

export const getWifiNetworks = (
    deviceInterface: BluetoothDeviceInterface,
    requestData: GetWifiNetworksRequestData
) =>
    deviceInterface
        .request<GetWifiNetworksRequestData, GetWifiNetworksResponseData>(
            'getWifiNetworks',
            requestData
        )
        .then(response => response.networks);
